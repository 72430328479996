<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="openCreateWebsiteDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="purple--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No websites yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <WebsiteListItem :attr="item" :link="!selectOne"></WebsiteListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateWebsite">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createWebsiteForm" ref="createWebsiteFormRef" @submit="validateCreateWebsite" onSubmit="return false;" @keyup.enter.native="validateCreateWebsite">
                    <div v-if="add">
                        <v-select :items="addWebsiteChoices" v-model="addWebsiteId" label="Select an website"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newWebsiteName
                            label="Display Name"
                            :rules="newWebsiteNameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="The name for the website"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="purple white--text" @click="addWebsite" :disabled="!createWebsiteForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="purple white--text" @click="createWebsite" :disabled="!createWebsiteForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateWebsiteDialog v-model="createWebsiteDialogVisible" @cancel="createWebsiteDialogVisible = false" @created="createWebsiteDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import WebsiteListItem from '@/components/list-item/WebsiteListItem.vue';
// import CreateWebsiteDialog from '@/components/account-dashboard/CreateWebsiteDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        WebsiteListItem,
        // CreateWebsiteDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a website"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createWebsiteDialogVisible: false,
        // create website
        displayCreateWebsite: false,
        createWebsiteForm: null,
        newWebsiteName: null,
        newWebsiteNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Website name is required',
        ],
        // add website
        addWebsiteChoices: [],
        addWebsiteId: null,
    }),

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an website';
            }
            switch (this.list.length) {
            case 0:
                return 'No websites';
            case 1:
                return '1 website';
            default:
                return `${this.list.length} websites`;
            }
        },
    },

    watch: {
        displayCreateWebsite(value) {
            if (value && (this.add || this.create)) {
                this.initAddWebsiteChoices();
            }
        },
    },

    methods: {
        async loadWebsiteList() {
            try {
                this.$store.commit('loading', { loadWebsiteList: true });
                const match = {};
                if (typeof this.domain === 'string') {
                    match.domain = this.domain;
                }
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsite(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadWebsiteList failed', err);
            } finally {
                this.$store.commit('loading', { loadWebsiteList: false });
            }
        },
        onClickItem(websiteId) {
            if (this.selectOne) {
                this.$emit('selected', { websiteId });
            }
        },
        openCreateWebsiteDialog() {
            // this.createWebsiteDialogVisible = true;
            this.$router.push({ name: 'account-create-website', params: { accountId: this.$route.params.accountId }, query: { domain: this.$route.params.domain } });
        },
        // async createWebsite() {
        //     this.error = false;
        //     console.log('createWebsite');
        //     const request = {
        //         // name: this.name,
        //         name: this.newWebsiteName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if website arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the website to an appropriate location after website is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createWebsite response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const website = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', website);
        //         this.$emit('added-site', website);
        //         this.displayCreateWebsite = false;
        //         this.newWebsiteName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateWebsite() {
        //     if (this.$refs.createWebsiteFormRef.validate()) {
        //         this.createWebsite();
        //     }
        // },

        // async addWebsite() {
        //     const website = { id: this.addWebsiteId, label: this.addWebsiteChoices.find((item) => item.value === this.addWebsiteId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', website);
        //     this.displayCreateWebsite = false;
        //     this.addWebsiteId = null;
        // },
        // async initAddWebsiteChoices() {
        //     // create a map of which website ids are already in the list
        //     const websiteMap = {};
        //     this.list.forEach((item) => {
        //         websiteMap[item.id] = true;
        //     });
        //     // when we show the add/create website dialog, load the list of available websites to add to the policy (list of all websites less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`WebsiteViewList.vue fetch websites result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove websites already in the policy, then convert the available websites to a choice list for the v-select [ { text, value }, ... ]
        //         this.addWebsiteChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadWebsiteList();
    },
};
</script>
